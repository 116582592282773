.footer {
  position: fixed; /* Fixa o rodapé na parte inferior da tela */
  left: 0;
  bottom: 0;
  width: 100%; /* Garante que o rodapé ocupe toda a largura da tela */
  background-color: #052244; /* Cor de fundo */
  color: #fff; /* Cor do texto */
  text-align: center;
  padding: 5px 0;
  font-size: 14px;
}

.footer-content span {
  display: block; /* Faz cada span aparecer em uma nova linha */
  margin: 5px 0; /* Espaçamento vertical entre linhas */
}
