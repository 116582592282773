/* .scroll-menu {
  display: flex;
  align-items: center;
  padding: 10px 0;
  position: -webkit-sticky; 
  position: sticky;
  top: 100px; 
  background-color: white;
  z-index: 1000; 
  margin-top: -4px;
} */

.scroll-menu {
 
  display: flex;
  align-items: center;
  padding: 10px 0;
  overflow-x: auto; /* Adiciona rolagem horizontal se necessário */
  white-space: nowrap; /* Mantém os itens do menu na mesma linha */
  position: -webkit-sticky; /* Para suporte em Safari */
  position: sticky;
  top: 100px; /* O menu vai "grudar" quando a posição de rolagem atingir 100px */
  background-color: white;
  z-index: 1000; /* Certifique-se que está acima de outros conteúdos */
  margin-top: -4px;
  -webkit-overflow-scrolling: touch; /* Melhora a rolagem em dispositivos iOS */
}

.menu-button {
  flex: none;  /* Impede que os botões estiquem */
  margin: 0 10px;
  cursor: pointer;
  font-size: 16px;
  padding: 10px 20px;
  border: none;
  background: none;
  transition: all 0.3s;
}

.menu-button.active {
  background: #052244!important;
  border-bottom: 3px solid #052244; /* Visual quando ativo */
  font-weight: bold;
  color: #fff;
  border-radius: 4px;
}

.menu-button:hover {
  background-color: #fff; /* Efeito ao passar o mouse */
}

.menu-selector {
  overflow-x: auto;
  white-space: nowrap;
  scrollbar-width: none;  
  -ms-overflow-style: none;  
  background-color: #fff;
  height: 70px;
 
}



@media (min-width: 769px) {

  .mobile-sticky {
    position: fixed; 
    top: 50px;
    z-index: 1050; 
    width: 100%;
  }

}

@media (min-width: 320px) and (max-width: 768px) {
  
  .mobile-sticky {
    position: fixed; 
    top: 50px;
    /* top: 132px;  */
    z-index: 1050; 
    width: 100%;

  }
 }


