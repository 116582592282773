.header {
  position: fixed;
  background-color: #052244;
  color: white;
  text-align: center;
  top: 0;
  z-index: 1050;
  height: 50px;
  padding: 10px; 
  font-size: 22px;
  width: 100%;
}


