
.App {
  font-family: 'Roboto', sans-serif;
}

body {
  font-family: 'Roboto', sans-serif;
}

.body-content {
  margin-top: 250px; /* Altura do cabeçalho + altura da imagem de capa */
 
}

* {
  box-sizing: border-box;
}

.container {
  display: flex;
  flex-wrap: wrap;
}

.items-container{
  background-color: #fff;
}

.no-padding {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.app-container .row > * {
  padding-right: 0;
  padding-left: 0;
}


.row {
  margin-right: 0;
  margin-left: 0; 
  width: 100%; 
}


.col-xs-12 {
  padding: 0; 
}

@media (min-width: 768px) {
  .col-6 {
    width: 50%; 
  }
}

@media (max-width: 767px) {
  .col-6 {
    width: 100%;
  }
} 

.sticky {
  position: fixed;
  top: 50px;
  background-color: white;
  width: 100%;
  z-index: 1050;
}