topo sticky{

  position: fixed; 
  top: 50px; 
  z-index: 1050; 
  width: 100%;

}

.selection-cardapio {
  appearance: none;
  border: 1px solid #0522440a;
  width: 100%; 
  z-index: 1040; 
  margin-top: 0px;
  
} 

 .select{
  color: #0522441a;
}

.selection-cardapio {
   background-color: rgba(255, 255, 255, 0.5);  
  color: #052244;
  padding: 8px!important;
  width: 100%;
  border: none; 
  border-radius: 0px!important; 
  box-sizing: border-box; 
  appearance: none;
  border: 1px solid #0522441a!important;
}


.selection-cardapio option {
  color: #052244; 
}


.selection-cardapio {
  background-color: rgb(255, 255, 255);
  color: #052244;
  padding: 10px;
  width: 100%;
  border-radius: 4px;
  box-sizing: border-box;
  appearance: none;
  border: 0px solid #0522441a;
  position: relative; 
  transition: all 0.3s; 
}



/* 
@media (min-width: 768px) {

  .selection-cardapio.sticky {
    width: 100%; 
  } 

  .selection-cardapio {
    width: 100%; 
  } 

  .select{
    width: 100%;
  }
}

@media (min-width: 1024px) {

  .selection-cardapio.sticky {
    width: 50%; 
  } 

  .selection-cardapio {
    width: 50%; 
  } 

  .select{
    width: 50%;
  }

}  */