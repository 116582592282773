

.search-bar {
  position: relative;
  width: 100%;
}

.search-input {
  appearance: none;
  border-radius: 0px!important; 
  box-sizing: border-box; 
  appearance: none;
  border: 1px solid #0522441a!important;
  padding-right: 30px; 
}




.search-clear-button {
  position: absolute;
  right: 10px;
  top: 50%; 
  transform: translateY(-50%); 
  border: none;
  background: transparent;
  cursor: pointer;
  font-size: 16px; 
}


.mobile-search-sticky{
  top: 100px;   
}

.search-input {
  background-color: rgb(255, 255, 255);
  color: #052244;
  padding: 8px;
  width: 100%;
  border-radius: 4px;
  box-sizing: border-box;
  appearance: none;
  border: 1px solid #0522441a;
  position: relative;
  transition: all 0.3s;
}


@media only screen and (max-width: 600px){

  .search-input {
    width: 100%; 
    margin-top: -2px!important;
  } 

  .search-bar.sticky {
    width: 100%; 
  } 

  .search-bar.sticky {
    position: fixed; 
    top: 92px; 
    z-index: 1050; 
   width: 100%;
  }
  
}

@media (min-width: 1024px) {

 

  .search-input {
    width: 100%; 
  } 

  .search-bar.sticky {
    width: 50%; 
  } 

}


