.cover-image {
  width: 100%;
  height: "auto";
  overflow: hidden;
  position: relative;
}

.cover-image img {
  width: 100%;
  height: auto; 
  max-height: 200px;
  object-fit: cover; 
  object-position: center top; 
  margin-top: 50px;
  z-index: 1030;
}


@media only screen and (max-width: 768px){
  .cover-image img {
     max-height: 100px;
  }
}