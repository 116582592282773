* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Inter, sans-serif;
}

@media (prefers-color-scheme: dark) {

  html {
    color-scheme: dark;
  }

  .header {
    color: #e8e9eb!important; 
    background-color: #052244!important;
  }

  .product-list    {
    background-color: #052244!important;
  }

  .menu-selector{
    background-color: #000!important;

  }

  .form-select {
    color: #fff!important; 
    background-color: #000!important;
  }


  .form-select selection-cardapio {
    color: #fff!important; 
    background-color: #000!important;
  }
  .search-input{
    color: #fff!important; 
    background-color: #000!important; 
  }

  .categoria{
    color: #97a1ac!important; 
    background-color: #000!important; 
  }

  .scroll-menu {
    color: #e8e9eb!important; 
    background-color: #000!important;  
  }

  .product-item-1{
 
    background-color: #eeebeb44!important;  
  }

  .product-title-1{
    color: #e3e8ec!important;
    
  }

  .selection-cardapio option {
    color: #fff!important; 
    /* color: #052244; */
  }


  .menu-button.active {
    border-bottom: 3px solid #1665c0!important;;
    font-weight: bold!important;
  }

  .menu-button:hover {
    background-color: #052244!important;
    color: #fff!important;
  }

  .titulo-categoria{

    color: #f1f1f1fb!important;

  }

  .product-item-price-1{
    color: #ffff00!important;
  }

}

/* Estilos básicos do corpo */
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--body-bg);
  color: var(--body-color);
}



.error-container {
  display: flex;
  flex-direction: column; /* Organiza o conteúdo em colunas */
  justify-content: center; /* Centraliza horizontalmente */
  align-items: center; /* Centraliza verticalmente */
  height: 100vh; /* Define a altura da div como 100% da viewport */
  background-color: #009cff; /* Cor de fundo azul */
}

.error-container img {
  max-width: 100%; /* Garante que a imagem não ultrapasse a largura da div */
  max-height: 100%; /* Garante que a imagem não ultrapasse a altura da div */
  margin-bottom: 20px; /* Espaçamento entre a imagem e o texto */
}

.error-container p {
  font-size: 24px; /* Tamanho do texto */
  color: white; /* Cor do texto */
}


/* Personaliza a barra de rolagem inteira */
::-webkit-scrollbar {
  width: 10px; /* ou qualquer largura que você desejar */
  height: 10px; /* altura para a barra de rolagem horizontal */
}

/* Personaliza a parte da trilha (parte da barra onde o thumb não cobre) */
::-webkit-scrollbar-track {
  background: #f1f1f1; /* uma cor de fundo mais clara para a trilha */
}

/* Personaliza o thumb (a parte da barra que você pode arrastar) */
::-webkit-scrollbar-thumb {
  background: #c1c1c1; /* uma cor cinza clarinho para o thumb */
  border-radius: 5px; /* bordas arredondadas para o thumb */
}

/* Adiciona um efeito ao passar o mouse */
::-webkit-scrollbar-thumb:hover {
  background: #a8a8a8; /* uma cor um pouco mais escura quando o mouse está sobre o thumb */
}
